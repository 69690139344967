import { createGlobalStyle } from 'styled-components'
import { colorsToken } from './colors'
import { Helmet } from 'react-helmet'

const StyledGlobalStyle = createGlobalStyle`
    * {
      font-family: "Inter";
      color: ${colorsToken.text.Primary}
    }

    body, html {
      margin: 0;
    }
`

function GlobalStyle() {
  return (
    <>
      {' '}
      <Helmet>
        {/* Script Google Analytics */}
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=GTM-PZRM2JFG"
        ></script>
        <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'GTM-PZRM2JFG');
      
          `}
        </script>

        {/* Fonts et autres éléments du head */}
        <link rel="preconnect" href="https://fonts.googleapis.com"></link>
        <link
          href="https://fonts.cdnfonts.com/css/lokanova"
          rel="stylesheet"
        ></link>
        <link
          href="https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap"
          rel="stylesheet"
        ></link>
      </Helmet>
      <StyledGlobalStyle />
    </>
  )
}

export default GlobalStyle
